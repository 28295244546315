/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';

// MUI
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

// MUI Icons
const Facebook = dynamic(() => import('@mui/icons-material/Facebook'), { loading: () => null });
const Twitter = dynamic(() => import('@mui/icons-material/Twitter'), { loading: () => null });
const Pinterest = dynamic(() => import('@mui/icons-material/Pinterest'), { loading: () => null });

// PRO
const MDSocialButton = dynamic(() => import('components/MDSocialButton'), { loading: () => null });

function Home() {
  return (
    <MDBox pt={18} pb={6}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
            <MDTypography variant="h4" component="h2" fontWeight="bold" mb={0.5}>
              Thank you for your support!
            </MDTypography>
            <MDTypography variant="body1" color="text">
              I deliver my best.
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            my={{ xs: 5, lg: 'auto' }}
            mr={{ xs: 0, lg: 'auto' }}
            sx={{ textAlign: { xs: 'center', lg: 'right' } }}
          >
            <MDSocialButton
              aria-label="Tweet this page on Twitter"
              component="a"
              href="https://twitter.com/intent/tweet?text=Check%20out%20codefox.com&amp;url=https%3A%2F%2Fcodefox.com"
              target="_blank"
              color="twitter"
              sx={{ mr: 1 }}
              startIcon={<Twitter />}
            >
              &nbsp;Tweet
            </MDSocialButton>
            <MDSocialButton
              aria-label="Share this page on Facebook"
              component="a"
              href="https://www.facebook.com/sharer/sharer.php?u=https://codefox.com"
              target="_blank"
              color="facebook"
              sx={{ mr: 1 }}
              startIcon={<Facebook />}
            >
              &nbsp;Share
            </MDSocialButton>
            <MDSocialButton
              aria-label="Pin this page on Pinterest"
              component="a"
              href="https://www.pinterest.com/pin/create/button/?url=https://codefox.com"
              target="_blank"
              color="pinterest"
              startIcon={<Pinterest />}
            >
              &nbsp;Pin it
            </MDSocialButton>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Home;
